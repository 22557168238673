import { template as template_e483980c593d436c8956e8c3cab503f5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e483980c593d436c8956e8c3cab503f5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
