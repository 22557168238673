import { template as template_6941396cdbae4ac78ec8f7d09cf99097 } from "@ember/template-compiler";
const FKText = template_6941396cdbae4ac78ec8f7d09cf99097(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
